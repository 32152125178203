<template>    
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.shares.historyTransactionStock')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="stock-buy" class="btn btn-success" @click="onBuyStocks">
                        {{ $t('pages.shares.buyStock') }}
                    </button>
                    <button type="button" name="stock-Sell" class="btn btn-danger" @click="onSellStocks">
                        {{ $t('pages.shares.sellStock') }}
                    </button>
                </template>
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="allStocktatus"
                                                     v-model="searchInfo.selectedStatusList"
                                                     :searchable="false"
                                                     :close-on-select="false"
                                                     :clear-on-select="false"
                                                     :limit="10"
                                                     :multiple="true"
                                                     :placeholder="$t('common.picksome')"
                                                     :reset-after="false"
                                                     label="label"
                                                     track-by="key">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.shares.transactionType') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchInfo.transactionType">
                                            <option value="-1" selected="selected">{{ $t('pages.shares.selectTransactionType') }}</option>
                                            <option value="1">{{ $t('pages.shares.buyStock') }}</option>
                                            <option value="2">{{ $t('pages.shares.sellStock') }}</option>
                                        </select>
                                    </div>
                                </div>                              
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="stockTransactionList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                        <template #footer v-if="stockTransactionList != null && stockTransactionList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{stockTransactionList == null ? 0: stockTransactionList.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #price_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.price)" />
                            </td>
                        </template>
                        <template #quantity_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.quantity)" />
                            </td>
                        </template>
                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.dateTime)" />
                            </td>
                        </template>
                        <template #transaction_type="{item}">
                            <td class="py-2">
                                <CButton :class="transactionTypeCss(item.transactionType)" v-text="transactionType(item.transactionType)" />
                            </td>
                        </template>
                        <template #transaction_status="{item}">
                            <td class="py-2">
                                <CButton :class="transactionStatusCss(item.status)" v-text="transactionStatus(item.status)" />
                            </td>
                        </template>
                        <template #stocktrading_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary" variant="outline" square size="sm" @click="onViewDetail(item, index)">{{ $t('common.detail') }}</CButton>
                                <CButton v-if="item.status == 0" class="btn-danger" square size="sm" @click="onCancelOrder(item, index)" style="margin-left:5px">{{ $t('pages.shares.cancelcommand') }}</CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>      
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import i18n from '@/plugins/i18n' 	
    import { mapState, mapActions } from 'vuex'
    import CommonStatus from '@/helpers/CommonStatus'
    import ListForm from '@/components/ListForm.vue'
    import Confirmation from '@/components/Confirmation.vue'
    
    export default {
        name: 'StockTradingList',
		data() {            
			return {                
				itemsPerPage: 10,
                warningModal: false,              
                fields: [
                    { key: 'transactionId', label: i18n.t('pages.shares.code') },
                    { key: 'userName', label: i18n.t('common.name') },
                    { key: 'price_text', label: i18n.t('common.amount') },
                    { key: 'quantity_text', label: i18n.t('common.quantity') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    { key: 'transaction_type', label: i18n.t('pages.shares.transactionType') },
                    { key: 'transaction_status', label: i18n.t('common.status') },
                    {
                        key: 'stocktrading_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
                allStocktatus: [
                    { key: 0, label: i18n.t('common.pending') },
                    { key: 1, label: i18n.t('common.completed') },
                    { key: 2, label: i18n.t('common.rejected') },
                    { key: 3, label: i18n.t('common.canceled') }
                ],

            };
		},
        components: {
            ListForm, Confirmation
        },
        computed: {                                   
            ...mapState('stockTrading', ['isLoading', 'searchInfo', 'stockTransactionList','isProcessing'])
        },
        methods: {
            ...mapActions('stockTrading', ['searchStockTrading', 'changeTradingStockStatus']),

            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            transactionType(type) {
                
                return type === 1 ? i18n.t('pages.shares.buy') : i18n.t('pages.shares.sell');
            },
            transactionTypeCss(type) {              
                    return type === 1 ? "btn-success" : "btn-danger";               
            },
            transactionStatus(iStatus) {
                if (CommonStatus.Completed === iStatus)
                    return i18n.t('common.completed');
                else if (CommonStatus.Rejected === iStatus)
                    return i18n.t('common.rejected');
                else if (CommonStatus.Canceled === iStatus)
                    return i18n.t('common.canceled');
                else
                    return i18n.t('common.pending');

            },
            transactionStatusCss(iStatus) {
                if (CommonStatus.Rejected === iStatus || CommonStatus.Canceled === iStatus)
                    return "btn-warning";
                else if (CommonStatus.Completed === iStatus)
                    return "btn-success";
                else
                    return "btn-danger";
            },           
            onSearch() {
                this.searchStockTrading({ pageNumber: 0 });
            },
            onBuyStocks() {
                this.$router.push('/shares/buystock');
            },
            onSellStocks() {
                this.$router.push('/shares/sellstock');
            },
            onViewDetail(item) {
                this.$router.push('/shares/detail/' + item.transactionId);
            },
            onCancelOrder(item) {
                this.$refs.confirmation.show(i18n.t('pages.shares.confirmcancelcommand'), async () => {
                    var retVal = await this.changeTradingStockStatus({ id: item.transactionId, newStatus: CommonStatus.Canceled });
                    
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.shares.noticesCancelCommandSuccess') });
                        this.onSearch();
                    }
                    else {
                        this.$store.dispatch('alert/error', i18n.t('pages.shares.noticesCancelCommandUnsuccess'), { root: true });
                    }
                });
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('stockTradingItemsPerPage', item)
            },
		},
        mounted() {
                        
            this.searchInfo.selectedStatusList = [{ key: CommonStatus.Pending, label: i18n.t('common.pending') }];

            // set page item
            let stockTradingItemsPerPage = parseInt(localStorage.getItem('stockTradingItemsPerPage'));
            if (isNaN(stockTradingItemsPerPage))
                stockTradingItemsPerPage = 10;
            this.itemsPerPage = stockTradingItemsPerPage;

            this.onSearch();
        }
	}
</script>