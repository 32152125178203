const CommonStatus = {
    Pending: 0,//Đang chờ duyệt
    Completed: 1,// hoàn tất gd
    Rejected: 2,// Từ chối  
    Canceled: 3,//Bị hủy  
    Processing: 4, // đang xử lý
};

Object.freeze(CommonStatus);
export default CommonStatus;




